<template>
  <div>
    <tab3 @openModalAlertText="openModalAlertText" :quantity="true" />
  </div>
</template>

<script>
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import Tab3 from "../../../eventsetting/eventsettingdetail/component/tap3.vue";
export default {
  name: "BranchCalendar",
  components: { ModalAlertText, Tab3 },
  data() {
    return {
      dataSchedule: {},
    };
  },
  mounted() {
    this.getScheduleData();
  },
  methods: {
    async getScheduleData() {
      const respone = await this.$services.venue.getSchedule(
        this.$route.params.id
      );
      if (respone.result >= 1) {
        this.dataSchedule = respone.detail;
      }
    },
    openModalAlertText(val) {
      this.textModal = val;
      this.$bvModal.show("modal-alert-text");
    },
  },
};
</script>

<style lang="scss" scoped></style>
